import content from "../../../config/cardContent";
import Card from "./MainViewContentCard";
import { Container } from "../../styles/Container.styled";

export default function MainView() {
  return (
    <Container>
      {content.map((item, index) => (
        <Card key={index} item={item} />
      ))}
    </Container>
  );
}
