import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.header};
  padding: 40px 0;
`;

export const StyledH2 = styled.h2`
  padding: 0;
`;

export const Nav = styled.nav<{ full?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ full }) => (full ? "40px" : "0px")};

  @media (max-width: ${({ theme }) => theme.tablet}) {
    button {
      padding: 10px 20px;
    }
    margin-bottom: ${({ full }) => (full ? "10px" : "0px")};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    button {
      padding: 5px 12px;
    }
    margin-bottom: ${({ full }) => (full ? "10px" : "0px")};
  }
`;

export const Logo = styled.img`
  width: 75px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 40px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 60px;
  }
`;

export const Image = styled.img`
  width: 500px;
  margin-left: 40px;
  border: 1px solid white;
  opacity: 1;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    width: 300px;
    margin-left: 0px;
    margin-top: 20px;
  }
`;

export const FloatingImage = styled.img`
  width: 400px;
  margin-left: 40px;
  animation: float 4s ease-in-out infinite;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    width: 300px;
    margin-left: 0px;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`;

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.headerText};

  &:hover {
    font-weight: 600;
  }
`;
