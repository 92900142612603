import { useNavigate } from "react-router-dom";
import {
  StyledHeader,
  Nav,
  Logo,
  Image,
  StyledH2,
} from "./styles/Header.styled";
import { Container } from "./styles/Container.styled";
import { Flex, FlexIcon } from "./styles/Flex.styled";
import NavBarButton from "./NavBarButton";
import ExternalButton from "./ExternalButton";

export default function Header() {
  const navigate = useNavigate();

  function handleHomeNavigate(url: string) {
    navigate(`/`);
    window.scrollTo(0, 0);
  }

  return (
    <StyledHeader>
      <Container>
        <Nav full={true}>
          <FlexIcon>
            <Logo
              src="../images/logo.png"
              alt=""
              onClick={() => handleHomeNavigate("")}
            />
            <StyledH2>404elements</StyledH2>
          </FlexIcon>
          {/* <div>
            <NavBarButton icon="about" url="about" text="About" />
            <NavBarButton icon="magic" url="tokenomics" text="Tokens" />
            <NavBarButton icon="shard" url="roadmap" text="Roadmap" />
          </div> */}
          <NavBarButton icon="picture" url="buy" text="Buy $ELMT" />
        </Nav>
        <div>
          <Flex>
            <div>
              <h1>404elements</h1>
              <h2>The atomic table of degeneracy.</h2>
              <p style={{ fontSize: "13px" }}>
                404elements is a degen-inspired collection of 10,000
                arbitrum-based replicants built using the novel ERC404 standard.
                There is no formal team or roadmap. 100% of $ELMT the was added
                as initial liquidity to be fair. No whitelist, no dev
                allocation, nothing. Good luck!
              </p>
              <ExternalButton
                url={
                  "https://arbiscan.io/token/0x9931beab40a46d8b71fd769f9fb21bfd208819e9"
                }
                color={"white"}
                content={"View Contract"}
              />
              <NavBarButton
                url={"https://twitter.com/404elements"}
                text={""}
                icon={"twitter"}
              />
              <NavBarButton
                url={"https://t.me/elements404"}
                text={""}
                icon={"telegram"}
              />
            </div>
            <Image src="../images/logo.gif" />
          </Flex>
        </div>
      </Container>
    </StyledHeader>
  );
}
