import SocialIcons from "./SocialIcons";
import { Container } from "./styles/Container.styled";
import { Flex, FlexIcon } from "./styles/Flex.styled";
import { StyledFooter } from "./styles/Footer.styled";
import { Logo, StyledH2 } from "./styles/Header.styled";

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <FlexIcon>
          <Flex>
            <Logo src="../images/logo.png" alt="" />
            <StyledH2>404elements</StyledH2>
          </Flex>
          <SocialIcons />
        </FlexIcon>

        <Flex>
          <ul>
            <li style={{ fontSize: 14 }}>
              $ELMT is a experimental token with no intrinsic value or
              expectation of financial return. There is no formal team or
              roadmap, token is for entertainment purposes only. Investing in
              $ELMT is considered high-risk and you should be prepared to accept
              the possibility of losing some of your investment. Please only
              invest what you can afford to lose and consult a financial advisor
              before investing. The value of $ELMT may fluctuate significantly,
              and we make no representation of its future performance.
            </li>
          </ul>
        </Flex>
      </Container>
    </StyledFooter>
  );
}
