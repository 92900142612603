import styled from 'styled-components';

export const Button = styled.button<{bg?: string, color?: string, navButton?: boolean}>`
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    font-family: Aileron;
    padding: 15px 25px;
    background-color: ${({ bg }) => bg || '#222222'};
    color: ${({ color }) => color || '#FFF'};
    margin-left: ${({ navButton }) => navButton ? "10px" : "0px"};

    &:hover {
        opacity: 0.8;
        transform: scale(0.95);
    }
`