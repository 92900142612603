import { Button } from "./styles/Button.styled";

export default function ExternalButton(props: any) {
  const openLinkInNewTab = () => {
    window.open(props.url, "_blank");
  };

  return (
    <Button color={props.color} bg={props.bg} onClick={openLinkInNewTab}>
      {props.content}
    </Button>
  );
}
