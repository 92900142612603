import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle<{ theme: any }>`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.headerText};
    font-family: Aileron, sans-serif;
    font-size: 1.15em;
    margin: 0;
  }
  
  p {
    color: ${({ theme }) => theme.colors.bodyText};
    line-height: 1.5;
  }

  h1 {
    font-size: 68px;
    font-weight: 100;
    margin: 0px
  }

  h2 {
    font-size: 20px;
    font-weight: 250;
  }

  img {
    max-width: 100%;
  }

  html {
    scroll-behavior: smooth
  }

  input {
    background: ${({ theme }) => theme.colors.body};
    border: 1px solid white;
  }

  @media(max-width: ${({ theme }) => theme.tablet}) {
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 14px;
      font-weight: 250;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export default GlobalStyles;
