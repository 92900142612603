import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import GlobalStyles from "./components/styles/Global";
import MainView from "./components/views/MainView";
import BuyView from "./components/views/BuyView";
import RoadmapView from "./components/views/RoadmapView";

const theme = {
  colors: {
    header: "black",
    body: "#111111",
    footer: "black",
    headerText: "white",
    lightBackground: "purple",
    bodyText: "white",
    inputBoxColor: "grey",
  },
  tablet: "768px",
  mobile: "500px",
};

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <MainView />
                  {/* <BuyView />
                  <RoadmapView /> */}
                </>
              }
            />
          </Routes>
          <Footer />
        </>
      </ThemeProvider>
    </Router>
  );
}

export default App;
