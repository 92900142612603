import styled from 'styled-components'

export const StyledFooter = styled.footer<{theme: any}>`
  background-color: ${({ theme }) => theme.colors.footer};
  color: ${({ theme }) => theme.colors.bodyText};
  padding: 40px 0 20px;

  ul {
    list-style-type: none;
  }

  ul li {
    margin-bottom: 20px;
  }

  p {
    text-align: right;
  }
  
  @media (max-width: ${({ theme }) => theme.tablet}) {
    text-align: center;
    ul {
      padding: 0;
    }
    p {
      text-align: center;
    }
  }
`