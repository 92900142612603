const content = [
  {
    id: 1,
    title: "innovative 404 standard",
    buttonText: "Learn More",
    link: "https://github.com/Pandora-Labs-Org/erc404",
    image: "crazy.gif",
    content:
      "Get in, loser. We're going to a new standard of NFT fractionalization and liquidity. 404s combine the power of ERC20 and ERC721, keeping them in sync in your wallet as you trade. Be careful, there's some kinks and it's a new standard!",
  },
  {
    id: 2,
    title: "100% fair launch",
    buttonText: "Buy $ELMT 🦍",
    link: "https://app.uniswap.org/#/swap?chain=arbitrum&inputCurrency=ETH&outputCurrency=0x9931beab40a46d8b71fd769f9fb21bfd208819e9",
    image: "tokenomics.png",
    content:
      "No presale? Yup. No whitelist? Yup. 100% in liquidity? Yup. All $ELMT are were placed in LPs, so everyone has a fair chance to participate.",
  },
  {
    id: 3,
    title: "what's your degen atom?",
    buttonText: "Join the Community 🤝",
    link: "https://t.me/elements404",
    image: "table.png",
    content:
      "A collection of 26 meme and degenracy inspired pieces of art, which one will you get? And how will you combine them to make the ultimate degen molecules?",
  },
];

export default content;
