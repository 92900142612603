import styled from "styled-components";

export const Container = styled.div`
  width: 1200px;
  max-width: 90%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const BuyContainer = styled.div`
  width: 1200px;
  max-width: 90%;
  padding: 0 20px;
  margin: 0 auto;
`;

export const ColorContainer = styled.div`
  background-color: #514538;
`;

export const ColorContainer2 = styled.div`
  background-color: #322a22;
`;

export const VhContainer = styled.div`
  width: 1000px;
  max-width: 90%;
  padding: 0 20px;
  margin: 0 auto;
  min-height: 70vh;
`;
