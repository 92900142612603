import { FaTwitter, FaTelegram, FaEthereum } from "react-icons/fa";
import { StyledSocialIcons } from "./styles/SocialIcons.style";

export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a
          href="https://twitter.com/404elements"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
      </li>
      <li>
        <a href="https://t.me/elements404" target="_blank" rel="noreferrer">
          <FaTelegram />
        </a>
      </li>
      <li>
        <a
          href="https://arbiscan.io/token/0x9931beab40a46d8b71fd769f9fb21bfd208819e9"
          target="_blank"
          rel="noreferrer"
        >
          <FaEthereum />
        </a>
      </li>
    </StyledSocialIcons>
  );
}
