import styled from "styled-components";

export const StyledCard = styled.div<{ layout: any; theme: any }>`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.header};
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin: 40px 20px;
  padding: 30px ${({ layout }) => (layout === "row" ? "0px" : "70px")} 30px 70px;
  flex-direction: ${({ layout }) => layout};

  img {
    width: 70%;
    border-radius: 15px;
  }

  & > div {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column;
    padding: 20px 60px;

    img {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 15px 30px;

    button {
      margin: 0 0 20px 0;
    }
  }
`;
