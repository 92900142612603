import { Button } from "../../styles/Button.styled";
import { StyledCard } from "../../styles/Card.styled";

export default function Card({
  item: { id, title, buttonText, image, link, content },
}: any) {
  function handleNavigate(url: string) {
    window.open(url, "_blank");
  }

  return (
    <StyledCard layout={id % 2 === 1 ? "row-reverse" : "row"}>
      <div>
        <h2>{title}</h2>
        <p>{content}</p>
        <Button onClick={() => handleNavigate(link)}>{buttonText}</Button>
      </div>
      <div>{image && <img src={`./images/${image}`} alt="" />}</div>
    </StyledCard>
  );
}
