import useIsMobile from "../hooks/useIsMobile";
import { Button } from "./styles/Button.styled";
import {
  FaCar,
  FaCoins,
  FaMoneyBill,
  FaFrog,
  FaTwitter,
  FaTelegram,
} from "react-icons/fa";

export default function NavBarButton(props: any) {
  function handleNavigate(url: string) {
    if (url === "buy") {
      window.open(
        "https://app.uniswap.org/#/swap?chain=arbitrum&inputCurrency=ETH&outputCurrency=0x9931beab40a46d8b71fd769f9fb21bfd208819e9",
        "_blank"
      );
    } else if (url === "about") {
      window.scrollTo(0, 600);
    } else if (url === "tokenomics") {
      window.scrollTo(0, 1200);
    } else if (url === "roadmap") {
      window.scrollTo(0, 2900);
    }
    window.open(url, "_blank");
  }

  function pickIcon(icon: string) {
    if (icon === "shard") {
      return <FaCar style={{ paddingTop: "3px" }} />;
    } else if (icon === "magic") {
      return <FaCoins style={{ paddingTop: "3px" }} />;
    } else if (icon === "about") {
      return <FaFrog style={{ paddingTop: "3px" }} />;
    } else if (icon === "twitter") {
      return <FaTwitter style={{ paddingTop: "3px" }} />;
    } else if (icon === "telegram") {
      return <FaTelegram style={{ paddingTop: "3px" }} />;
    } else {
      return <FaMoneyBill style={{ paddingTop: "3px" }} />;
    }
  }

  return (
    <Button
      bg={props.text === "Soon" ? "#111111" : ""}
      color={props.text === "Soon" ? "white" : ""}
      onClick={() => handleNavigate(props.url)}
      navButton={true}
    >
      {pickIcon(props.icon)} <span hidden={useIsMobile()}>{props.text}</span>
    </Button>
  );
}
